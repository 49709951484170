header {
  display: flex;
  transition: 0.4s;
  justify-content: space-between;
  z-index: 1;
}

header:hover {
  background-color: yellow;
}

.header-link {
  color: #777777;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-decoration: none;
  font-size: larger;
  margin: 3em 2em 1.5em auto;
}

.header-image {
  height: 6em;
  margin: 1.5em 2em 1.5em 1em;
  filter: hue-rotate(10deg) sepia(80%);
}

.darkmode-toggler {
  margin: 3.1em 3em 3em 1em;
}

.computer-view-header {
  display: auto;
}
.phone-view-header {
  display: none;
}

@media (max-width: 800px) {
  .mui-appbar {
    box-shadow: none;
  }
  .computer-view-header {
    display: none;
  }
  .phone-view-header {
    display: inherit;
  }
  header {
    background-color: #66666666;
  }
  .logo-firma-mobile {
    height: 6vh;
    filter: hue-rotate(225deg);
  }
}
