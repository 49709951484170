.presentation-text-container {
  color: aliceblue;
  margin: auto 3rem auto 3rem;
  font-size: xx-large;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: #66666666;
}

.cursor {
  visibility: hidden;
  animation: blink 1.2s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.foto-de-pedro {
  width: "90%";
}
@media (max-width: 800px) {
}
@media (max-width: 800px) {
  body {
    background-image: url("/public/CSRondaSur.JPG");
    background-size: cover;
  }

  .foto-de-pedro {
    display: none;
  }
}
